@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.text-right {
  text-align: right !important;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f0f0f0
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
  border-radius: 50%
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d2d2d2
}

.k-input-values {
  display: flex;
}